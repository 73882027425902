const images = {
  ic_logout: require('../../images/ic_logout.png'),
  ic_default_avatar: require('../../images/ic_default_avatar.png'),
  ic_input_file: require('../../images/ic_input_file.png'),
  ic_photo: require('../../images/ic_photo.png'),
  ic_sticker: require('../../images/ic_sticker.png'),
  ic_send: require('../../images/ic_send.png'),
  ic_wave_hand: require('../../images/ic_wave_hand.png'),

  mimi1: require('../../images/mimi1.gif'),
  mimi2: require('../../images/mimi2.gif'),
  mimi3: require('../../images/mimi3.gif'),
  mimi4: require('../../images/mimi4.gif'),
  mimi5: require('../../images/mimi5.gif'),
  mimi6: require('../../images/mimi6.gif'),
  mimi7: require('../../images/mimi7.gif'),
  mimi8: require('../../images/mimi8.gif'),
  mimi9: require('../../images/mimi9.gif')
}

export default images
